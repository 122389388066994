.medium {
  @apply font-medium;
}

.bold {
  @apply font-bold;
}

.primary {
  @apply text-black;
}
:global(.dark) .primary {
  @apply text-white;
}

.secondary {
  @apply text-neutral-500;
}
:global(.dark) .secondary {
  @apply text-neutral-400;
}

.corporate {
  @apply text-tesla-red;
}

.sm {
  @apply text-sm;
}

.base {
  @apply text-base;
}

.bold {
  @apply font-bold;
}

.lg {
  @apply text-base lg:text-xl;
}
