@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 300px
    ),
    fixed 0 0 / 20px 20px radial-gradient(#d1d1d1 1px, transparent 0),
    fixed 10px 10px / 20px 20px radial-gradient(#d1d1d1 1px, transparent 0);
}

.dark body {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #111 300px),
    fixed 0 0 / 20px 20px radial-gradient(#313131 1px, transparent 0),
    fixed 10px 10px / 20px 20px radial-gradient(#313131 1px, transparent 0);
}

figcaption {
  font-size: 0.85rem;
  line-height: 1.5rem;
  display: block;
  text-align: center;
  margin-top: 0.5rem;
}

code.text-\[\.9em\] {
  font-size: 14px;
}

s,
strike {
  text-decoration: none;
  position: relative;
}
s::before,
strike::before {
  top: 50%;
  background: red;
  opacity: 0.9;
  content: '';
  width: 110%;
  position: absolute;
  height: 0.2em;
  border-radius: 0.1em;
  left: -5%;
  white-space: nowrap;
  display: block;
  transform: rotate(-15deg);
}
s.straight::before,
strike.straight::before {
  transform: rotate(0deg);
  left: -1%;
  width: 102%;
}
