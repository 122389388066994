.gradientSectionBorder {
  --height: 100px;
  position: relative;
  overflow: hidden;
  will-change: filter;
}

.gradientSectionBorderLeft {
  position: absolute;
  width: 50vw;
  height: var(--height);
  left: 0;
  background: #e82127;
  border-radius: 100%;
  mix-blend-mode: normal;
  filter: blur(200px);
}

.gradientSectionBorderRight {
  width: 50vw;
  position: absolute;
  height: var(--height);
  right: 0;
  background: #e82127;
  border-radius: 100%;
  mix-blend-mode: normal;
  filter: blur(200px);
}

.gradientSectionBorderDivider {
  background: #e82127;
}
